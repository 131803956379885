<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Cart") }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
      class="cdcdcdcds"
    >
      <div class="container " v-if="!done">
        <div class="list-group  mr-5 ml-5 mb-2">
          <a
            class="list-group-item list-group-item-action"
            v-for="(item, index) in list"
            :key="item.id"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                <img
                  :src="$baseUploadURL + item.image"
                  style="width: 50px;"
                  alt=""
                />

                {{ item.pname }} ({{ item.quantity * item.price }}€)
                <br />
                <small> {{ item.code }} -{{ item.size }}ML </small>
              </h5>
              <p class="mb-1">
                <b-form-spinbutton
                  id="sb-default"
                  @change="chane()"
                  v-model="item.quantity"
                  :min="1"
                  class="mb-2"
                ></b-form-spinbutton>
              </p>
              <small
                style="font-size: 176%;color: #af002d;"
                @click="remove(index)"
                >x</small
              >
            </div>
          </a>
        </div>
        <div
          class="text-center mb-3"
          style="font-size: 21px;font-weight: bold;"
        >
          Total :
          {{ list.map((p) => p.quantity * p.price).reduce((a, b) => a + b, 0) }}
          €
        </div>

        <button
          v-if="list.length > 0"
          @click="done = true"
          class="btn btn-block btn-more"
        >
          {{ $t("Next") }}
        </button>
      </div>
      <div class="container" v-else>
        <form method="post" class="row">
          <div class="col-md-2">
            <div class="mb-3">
              <label for="sexType" class="form-label">
                {{ $t("Type") }}
              </label>

              <b-form-select v-model="body.sexType" class="mb-3">
                <b-form-select-option value="MR">{{
                  $t("MR")
                }}</b-form-select-option>
                <b-form-select-option value="MRS">{{
                  $t("MRS")
                }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>

          <div class="col-md-5">
            <div class="mb-3">
              <label for="fname" class="form-label">
                {{ $t("First Name") }}</label
              >
              <input
                type="text"
                class="form-control"
                id="fname"
                v-model="body.fname"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="mb-3">
              <label for="lname" class="form-label">{{
                $t("Last Name")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="lname"
                v-model="body.lname"
              />
            </div>
          </div>

          <div class="col-md-10">
            <div class="mb-3">
              <label for="address" class="form-label">
                {{ $t("AddressHome") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="body.address"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-3">
              <label for="zip" class="form-label">
                {{ $t("zip") }}
              </label>
              <input
                type="number"
                class="form-control"
                id="zip"
                v-model.number="body.zip"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="city" class="form-label">
                {{ $t("city") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="city"
                v-model="body.city"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="country" class="form-label">
                {{ $t("country") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="country"
                v-model="body.country"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="phone" class="form-label">{{ $t("Phone") }}</label>

              <vue-tel-input
                v-model.trim="body.phone"
                v-bind="{
                  mode: 'international',
                }"
                :allCountries="allCountries"
              ></vue-tel-input>
            </div>
          </div>
          <div class="col-md-6">
            <div class=" mb-3">
              <label for="validationTooltip04">{{ $t("Pay Type") }}</label>
              <select
                class="custom-select"
                id="validationTooltip04"
                v-model="body.paymentMethod"
              >
                <option value="cash">{{ $t("Cash") }}</option>
                <!-- <option value="online">{{ $t("Online") }}</option> -->
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label for="validationTooltip04">{{ $t("Recieve Type") }}</label>
              <select
                class="custom-select"
                id="validationTooltip04"
                v-model="body.recieverType"
              >
                <option value="himself">
                  {{ $t("Receive by himself") }}
                </option>
                <!-- <option value="post">{{ $t("post") }}</option> -->
              </select>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <p>
              {{ $t("Note:We can send2") }}
            </p>
          </div> -->
        </form>
        <button @click="save()" class="btn btn-block btn-more">
          {{ $t("buying") }}
        </button>
      </div>
    </section>

    <b-modal v-model="ppp" id="modal-prevent-closing">
      <b-form @submit.prevent="loginForm" method="post">
        <b-form-group>
          <b-form-input
            v-model="user.email"
            placeholder="Email Address"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input
            type="password"
            v-model="user.password"
            placeholder="Password"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-left"
            @click="ppp = false"
          >
            {{ $t("Close") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="loginForm"
            style="margin: 0.25rem;"
          >
            {{ $t("login") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();

    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return {
      list: [],
      done: false,
      body: {
        sexType: "MR",
        fname: null,
        lname: null,
        phone: null,
        address: null,
        paymentMethod: "cash",
        zip: null,
        city: null,
        country: null,
        recieverType: "himself",
        usersId: null,

        date: `${ye}-${mo}-${da}`,
      },
      ppp: false,
      byPost: 0,
      user: {
        email: null,
        password: null,
      },
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
    };
  },
  methods: {
    save() {
      if (
        this.body.fname &&
        this.body.lname &&
        this.body.phone &&
        this.body.sexType
      ) {
        this.$eventHub.$emit("showSpinner", true);

        let total = 0;

        for (let item of this.list) {
          const t = item.price * item.quantity;
          total = total + t;
        }

        let cartsItemsList = [];

        for (let item of this.list) {
          cartsItemsList.push({
            productsId: item.id,
            date: this.body.date,
            quantity: item.quantity,
            price: item.price,
          });
        }
        this.$http
          .post(`carts`, {
            ...this.body,
            cartsItemsList,
            total,
          })
          .then(
            (res) => {
              delete localStorage.itemsList;
              this.$eventHub.$emit("addItem", {});
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "success",
                title: this.$t("Success"),
                text:
                  this.body.paymentMethod == "cash"
                    ? this.$t("Success Done")
                    : this.$t("toBay"),
                showConfirmButton: false,
                timer: 1500,
              });

              if (this.body.paymentMethod == "cash") {
                // this.submit();
                this.$router.push("/");
              } else {
                this.$router.push(
                  "/PaymentCart/" +
                    res.data.data.id +
                    "/" +
                    (total + this.byPost)
                );
              }
            },
            (err) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "error",
                title: this.$t("Error"),
                text: this.$t("Something has gone wrong"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    remove(index) {
      this.list.splice(index, 1);
      localStorage.itemsList = JSON.stringify(this.list);
      this.$eventHub.$emit("addItem", {});
    },
    getNewCount() {
      this.list = localStorage.itemsList
        ? JSON.parse(localStorage.itemsList)
        : [];
    },
    chane() {
      localStorage.itemsList = JSON.stringify(this.list);
      this.$eventHub.$emit("addItem", {});
    },
  },

  mounted() {
    this.getNewCount();
    this.$eventHub.$on("addItem", () => {
      this.getNewCount();
      this.done = false;
    });

    this.$http.post(`users/getProfile`, {}).then((res) => {
      const user = res.data.data;

      this.body.usersId = user.id;
      this.body.email = user.email;
      this.body.phone = user.phone;
      this.body.sexType = user.sexType;
      this.body.fname = user.fname;
      this.body.lname = user.lname;
      this.body.address = user.address;
      this.body.zip = user.zip;
      this.body.city = user.city;
      this.body.country = user.country;
    });
  },

  watch: {
    // 'body.paymentMethod'(val) {
    //   if (val == false) {
    //     if (!sessionStorage.adminSession) {
    //       // this.ppp = true;
    //       this.body.paymentMethod = true;
    //     }
    //   }
    // },
    "body.recieverType"(val) {
      if (val == "post") {
        this.byPost = 7;
      } else {
        this.byPost = 0;
      }
    },
  },
  head: {
    title: {
      inner: "Cart",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>

<style></style>
